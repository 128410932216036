import React from 'react'
import PropTypes from 'prop-types'
import { List, Grid } from '@patomation/react-ui-components'

const Summary = ({cart}) =>
<div>
  <List>
      <Grid
        col={6} style={{textAlign:'center'}}>
        <div style={{gridColumn: 'auto / span 3'}}>Tour</div>
        <div>Travelers</div>
        <div>Date</div>
        <div>Price</div>
      </Grid>

    {cart.map( (item, index) =>

      <Grid
        key={`summary_item_${index}`}
        col={6}>
        <div style={{gridColumn: 'auto / span 3'}}>{item.title}</div>
        <div style={{textAlign:'center'}}>{item.travelers}</div>
        <div style={{textAlign:'center'}}>{item.date}</div>
        <div style={{textAlign:'right'}}>USD {item.basePrice + (item.personPrice * item.travelers)}.00</div>
      </Grid>

    )}
  </List>
</div>

Summary.propTypes = {
  cart: PropTypes.array
}

export default Summary
