import React, { useState } from 'react'
import Layout from '../components/Layout.js'
import {  config, Icon, Row, Gutter, Button, Hr, Loader, Grid } from '@patomation/react-ui-components'
import { Link, navigate } from 'gatsby'
import useGlobal from '../hooks/useGlobal'
import useMutation from '../hooks/useMutation'
import CheckoutTabs from '../components/CheckoutTabs'
import Summary from '../components/Summary'
import Totals from '../components/Totals'


import { PayPalButton } from '../vendor/react-paypal-button-v2'

const Payment = () => {

  const [ globalState ] = useGlobal({cart:[], bookingId: null})

  //Set total price
  const calculatePrice = () =>
    globalState.cart.reduce( (acc, {travelers, basePrice, personPrice}) => {
      acc += basePrice && personPrice
        ? basePrice + (personPrice * travelers)
        : 0 //use zero in case something is undefined so NaN doesn't show up
      return acc
    }, 0)

  const PAYMENT_MADE = `
    mutation {
      paymentMade (
        id: "${globalState.bookingId}",
        input: {
          paid: $paid
          currency: "$currency"
          status: "$status"
          payerEmail: "$payerEmail"
          payerFirstName: "$payerFirstName"
          payerLastName: "$payerLastName"
          payerID: "$payerID"
          orderID: "$orderID"
        }){
          _id
        }
    }
  `

  const [paymentMade, { loading }] = useMutation(
    PAYMENT_MADE, {
      onCompleted: () => {
        //todo clear local storage? cart? id?
        navigate('/success')
      }
    })

  const [paymentInProgress, setPaymentInProgress] = useState(false)
  
  const [ playFullAmount, setPayFullAmount ] = useState(true)

  if(loading) return <Layout><Loader /></Layout>

  return (
   <Layout aboveMain={<CheckoutTabs active='Payment'/>}>

    <section>
      {paymentInProgress === true
        ? <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(255,255,255,0.5)',
            top: 0,
            left: 0,
            zIndex: 110
          }}>
            <Loader />
          </div>
        : null }

      <Summary cart={globalState.cart} />


      <Hr/><Gutter />

      <Totals totalPrice={calculatePrice()} />
      <Gutter />

        <b style={{fontSize: '0.8rem', color:'red'}}>
          Deposit non-refundable
        </b>

      <Gutter />
      <Hr/><Gutter />


      <Row style={{
        justifyItems: 'top',
        alignItems: 'top'
      }}>

        <Link to='/checkout'>
          <Button color='#86AC41' kind='outline'>
            <Icon name='keyboard_backspace' color='#86AC41'/><Gutter vertical />
            Back to info
          </Button>
        </Link>

        <Gutter vertical />

        <div style={{marginLeft: 'auto'}} >
          <Grid
            col={2}
            style={{
              borderRadius: config.size.corners,
              border: '1px solid #86AC41',
              overflow:'hidden'
            }} >
            <Button
              onClick={() => { setPayFullAmount(true) }}
              color={playFullAmount ? '#324851' : '#86AC41'}
              background={playFullAmount ? '#86AC41' : '#ffffff'}
              style={{
                borderRadius: 0,
                padding: 0,
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                ...(playFullAmount ? {
                  fontWeight: 'bold'
                } : null)
              }}>
                <div style={{fontSize: '0.5rem', paddingBottom: '0.25rem'}}>Pay Full Amount</div>
                <div>{`$${calculatePrice()}`}</div>
            </Button>
            <Button
              onClick={() => { setPayFullAmount(false) }}
              color={!playFullAmount ? '#324851' : '#86AC41'}
              background={!playFullAmount ? '#86AC41' : '#ffffff'}
              style={{
                borderRadius: 0,
                padding: 0,
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                ...(!playFullAmount ? {
                  fontWeight: 'bold'
                } : null)
              }}>
                <div style={{fontSize: '0.5rem', paddingBottom: '0.25rem'}}>Pay Deposit</div>
                <div>{`$${Math.round(calculatePrice() * 0.3)}`}</div>
            </Button>
          </Grid>
          <Gutter />

          <PayPalButton
            // amount={"0.01"}
            amount={ playFullAmount ? calculatePrice() : Math.round(calculatePrice() * 0.3) } //TODO change to production
            onClick={()=>{
              console.log('paypal button clicked');
              setPaymentInProgress(true)
            }}
            currency={'USD'}
            options={{
              //SANDBOX
              // clientId: 'AX-qdrZ92dWSLy-So9vPo-Gr3jXR48bkvBKlns69iJ26aDiLwZtWligzFqCeeT-zwRNlGK0Nn6vMSdSL',
              //Live
              clientId: 'AXSLLUqoWbD3qmZ9vPpDusgMj1BWeWtHThE7N3oMAKpIXvpH4eApVDfWX2m0myKsB_CHg6WOYS4NJahZ',
              commit: true
            }}
            onSuccess={ (details, data) => {
              paymentMade({
                variables: {
                  paid: details.purchase_units[0].amount.value,
                  currency: details.purchase_units[0].amount.currency_code,
                  status: details.status,
                  payerEmail: details.payer.email_address,
                  payerFirstName: details.payer.name.given_name,
                  payerLastName: details.payer.name.surname,
                  payerID: data.payerID,
                  orderID: data.orderID
                }
              })
            }}
            onError={ error => {
              console.error(error);
              setPaymentInProgress(false)
            }}
            onCancel={ result => {
              console.error('playment canceled')
              setPaymentInProgress(false)
            }}
            />
        </div>
      </Row>

    </section>
   </Layout>
 )

}

export default Payment
